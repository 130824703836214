<!-- 团长申请管理页面 -->
<template>
  <div class="colonelApplyForManagement">
    <div class="head-area clr">
      <div class="fl">
        <div class="fl head-label-area">用户搜索：</div>
        <Input
          class="fl"
          @on-search="search"
          v-model="searchText"
          placeholder="请输入"
          clearable
          style="width:360px"
        >
          <template #prepend>
            <Select v-model="searchType" style="width: 100px">
              <Option value="1">用户昵称</Option>
              <Option value="2">id</Option>
            </Select>
          </template>
        </Input>
      </div>

      <div class="fr">
        <Button type="primary" style="margin:0 10px" @click="search">搜索</Button>
        <Button @click="reset">重置</Button>
      </div>
    </div>
    <div class="table-area">
      <Table :columns="columns" :data="dataList" :height="tableHeight">
        <template #avatar="{ row }">
          <img style="width:36px;height:36px;margin-top:5px" :src="row.avatar" alt />
        </template>
        <template #action="{ row }">
          <a @click="audit(row)" v-if="row.auditStatus == 1">审核</a>
        </template>
        <template #auditStatus="{ row }">
          <div v-if="row.auditStatus == 1">待审核</div>
          <div v-if="row.auditStatus == 2">审核通过</div>
          <div v-if="row.auditStatus == 3">审核不通过</div>
        </template>
        <template #status="{ row }">
          <div v-if="row.status == 0">未实名认证</div>
          <div v-if="row.status == 1">只认证二要素未人脸认证</div>
          <div v-if="row.status == 2">已实名认证</div>
          <div v-if="row.status == 3">实名未通过</div>
          <div v-if="row.status == 4">黑名单</div>
          <div v-if="row.status == 5">注销</div>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        show-total
        :page-size="size"
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
    <Drawer title="审核" v-model="modelShow" width="600" :mask-closable="false" :styles="styles">
      <auditBox ref="auditBox" v-if="modelShow" :currentData="currentData" />
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="modelShow = false">取消</Button>
        <Button type="primary" @click="save">确定</Button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import auditBox from './components/auditBox.vue'
import { getApplyList, auditApplyColonel } from '@/api/colonelCenter.js'
export default {
  components: {
    auditBox
  },
  data() {
    return {
      modelShow: false,
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
      searchType: '1',
      searchText: '',
      tableHeight: window.innerHeight - 250,
      total: 0,
      current: 1,
      size: 20,
      columns: [
        {
          title: '用户ID',
          key: 'id'
        },
        {
          title: '审核理由',
          key: 'auditMsg',
        },
        {
          title: '审核状态',
          slot: 'auditStatus'
        },
        {
          title: '头像',
          key: 'avatar',
          slot: 'avatar'
        },
        {
          title: '用户名',
          key: 'nickname'
        },
        // {
        //   title: '邀请码',
        //   key: 'invitationCode'
        // },
        {
          title: 'VIP等级',
          key: 'vipLevel'
        },
        {
          title: '手机号',
          key: 'mobile',
          width: 110
        },
        {
          title: '实名认证状态',
          key: 'status',
          width: 110,
          slot: 'status',
        },
        // {
        //   title: '推荐人姓名',
        //   key: 'parentName',
        //   width: 110
        // },
        {
          title: '申请团长理由',
          key: 'reason',
        },
        {
          title: '操作',
          slot: 'action',
        }
      ],
      dataList: [],
      currentData: {}
    };
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    reset() {
      this.searchType = '1'
      this.searchText = ''
      this.current = 1
      this.findPageDataList()
    },
    search() {
      this.current = 1
      this.findPageDataList()
    },
    findPageDataList() {
      let params = { pageIndex: this.current, size: this.size }
      if (this.searchType == 1 && this.searchText) {
        params.nickName = this.searchText
      } else if (this.searchType == 2 && this.searchText) {
        params.id = this.searchText
      }
      getApplyList(params).then(res => {
        console.log(res)
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
    audit(row) {
      this.currentData = JSON.parse(JSON.stringify(row))
      this.modelShow = true
    },
    save() {
      let params = this.$refs.auditBox.formData
      auditApplyColonel(params).then(res => {
        if (res.success) {
          this.$Message.success('审核成功！')
          this.current = 1
          this.findPageDataList()
          this.modelShow = false
        }
      })
    }
  }
}

</script>
<style lang='scss' scoped>
.colonelApplyForManagement {
  .head-label-area {
    line-height: 36px;
  }
}
</style>